import styled from 'styled-components';

const FooterStyle = styled.div`
  .row {
    color: white;
    background-color: #28282a;
    border-top: 10px solid #0d111b;
  }

  .content-footer {
    height: 230px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .copyright {
      color: #ffffff;
      margin-top: 16px;
      margin-bottom: 16px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 100;
      font-size: 16px;
      line-height: 29px;
      letter-spacing: 0.005em;
      opacity: 0.38;
    }

    .terms-container {
      display: flex;
      flex-direction: row;
      color: #ffffff;
      font-family: 'Roboto';
      font-style: normal;
      font-size: 14px;
      opacity: 0.55;

      .terms-link {
        color: #ffffff;
        font-family: 'Roboto';
        font-style: normal;
        font-size: 14px;
        opacity: 0.55;
      }
    }
  }
`;

export default FooterStyle;
