import React from 'react';

type Props = {
  color?: string;
};

const IstagramIcon = ({ color = 'white' }: Props): React.ReactElement => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.60511 3.51093C5.50619 3.51093 3.81319 5.16068 3.81319 7.20598C3.81319 9.25128 5.50619 10.901 7.60511 10.901C9.70403 10.901 11.397 9.25128 11.397 7.20598C11.397 5.16068 9.70403 3.51093 7.60511 3.51093ZM7.60511 9.60824C6.24873 9.60824 5.13987 8.53092 5.13987 7.20598C5.13987 5.88104 6.24543 4.80372 7.60511 4.80372C8.96479 4.80372 10.0704 5.88104 10.0704 7.20598C10.0704 8.53092 8.96149 9.60824 7.60511 9.60824ZM12.4366 3.35979C12.4366 3.83895 12.0406 4.22164 11.5521 4.22164C11.0604 4.22164 10.6677 3.83574 10.6677 3.35979C10.6677 2.88384 11.0637 2.49793 11.5521 2.49793C12.0406 2.49793 12.4366 2.88384 12.4366 3.35979ZM14.948 4.23451C14.8919 3.08001 14.6213 2.05736 13.7534 1.2148C12.8887 0.372238 11.8393 0.108536 10.6545 0.0506501C9.43342 -0.0168834 5.77351 -0.0168834 4.55243 0.0506501C3.37097 0.10532 2.32151 0.369022 1.45356 1.21158C0.585606 2.05414 0.318291 3.07679 0.258887 4.23129C0.189583 5.42117 0.189583 8.98757 0.258887 10.1774C0.31499 11.3319 0.585606 12.3546 1.45356 13.1972C2.32151 14.0397 3.36767 14.3034 4.55243 14.3613C5.77351 14.4288 9.43342 14.4288 10.6545 14.3613C11.8393 14.3066 12.8887 14.0429 13.7534 13.1972C14.618 12.3546 14.8886 11.3319 14.948 10.1774C15.0173 8.98757 15.0173 5.42438 14.948 4.23451ZM13.3705 11.4542C13.1131 12.0845 12.6148 12.5701 11.9647 12.8241C10.9911 13.2004 8.68097 13.1135 7.60511 13.1135C6.52925 13.1135 4.21582 13.1972 3.24556 12.8241C2.59872 12.5733 2.10039 12.0877 1.83968 11.4542C1.45356 10.5055 1.54266 8.25435 1.54266 7.20598C1.54266 6.1576 1.45686 3.90327 1.83968 2.9578C2.09709 2.32749 2.59542 1.84189 3.24556 1.58784C4.21912 1.21158 6.52925 1.29841 7.60511 1.29841C8.68097 1.29841 10.9944 1.2148 11.9647 1.58784C12.6115 1.83868 13.1098 2.32428 13.3705 2.9578C13.7567 3.90649 13.6676 6.1576 13.6676 7.20598C13.6676 8.25435 13.7567 10.5087 13.3705 11.4542Z"
      fill={color}
    />
  </svg>
);

export default IstagramIcon;
