import React from 'react';

type Props = {
  color?: string;
};

const FacebookIcon = ({ color = 'white' }: Props): React.ReactElement => (
  <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.5402 8.10673L7.95862 5.4985H5.34243V3.80594C5.34243 3.09238 5.70789 2.39683 6.8796 2.39683H8.06897V0.176209C8.06897 0.176209 6.98965 0 5.95771 0C3.8032 0 2.39491 1.24922 2.39491 3.51066V5.4985H0V8.10673H2.39491V14.412H5.34243V8.10673H7.5402Z"
      fill={color}
    />
  </svg>
);

export default FacebookIcon;
