import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';

import FooterStyle from '@app/components/Footer/style';
import { privacyLink, termsLink } from '@app/utils/links';
import { FormattedMessage } from 'react-intl';

const Footer = (): React.ReactElement => (
  <FooterStyle>
    <Container fluid>
      <Row className="justify-content-md-center">
        <Col xs sm="12">
          <div className="content-footer">
            <img src="/img/logo_branca.png" alt="logo" />
            <div className="copyright">
              <FormattedMessage id="footer.copyright" />
            </div>
            <div className="terms-container">
              <a href={termsLink} className="terms-link">
                <FormattedMessage id="footer.terms" />
              </a>
              <span className="mx-3">|</span>
              <a href={privacyLink} className="terms-link">
                <FormattedMessage id="footer.privacyPolicy" />
              </a>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  </FooterStyle>
);

export default Footer;
